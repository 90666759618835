import type { IManagerFormObjectValue } from '@manager'
import { parseValue } from '@manager/utils/diffManagerForm/utils'
import { isObject } from '@shared/utils/isObject'
import { REPEATER_ITEM_STATUS } from '../composables/useRepeater'

interface IMergeKindObjectDataArgs {
  tValue: IManagerFormObjectValue
  sValue: IManagerFormObjectValue
  iValue?: any
  isEvaluation?: boolean
}

/*
 * Merges the `data` property of two nodes of kind `OBJECT`
 */
function _mergeKindObjectData(args: IMergeKindObjectDataArgs) {
  const { tValue, sValue, iValue, isEvaluation } = args
  const tData = tValue.data
  const sData = sValue.data
  const iData = iValue?.data
  const maxLength = Math.max(tData.length, sData.length)

  for (let i = 0; i < maxLength; i++) {
    if (i < tData.length && i < sData.length) {
      // Merge if both arrays have elements at this index
      mergeForm({
        target: tData[i],
        source: sData[i],
        ignore: iData?.[i],
        isEvaluation,
      })
    } else if (i >= tData.length && i < sData.length) {
      if (iData && i in iData) continue
      // Add new items from srcData if objValue.data has fewer elements
      tData.push(sData[i])
    } else if (i < tData.length && i >= sData.length) {
      if (iData && i in iData) continue
      // Remove extra items from objValue.data if srcData has fewer elements
      tData.splice(i, 1)
      i-- // Decrement index to account for removal
    }
  }

  return tValue
}

/*
 * Determines if the value is a node of kind `OBJECT` (an object with a 'data' property)
 */
function _isKindObject(value: unknown): value is IManagerFormObjectValue {
  return isObject(value) && 'data' in value
}

interface IMergeFormArgs<
  TTarget extends Record<string, any>,
  TSource extends Record<string, any>,
> {
  target: TTarget
  source: TSource
  ignore?: Record<string, any>
  isEvaluation?: boolean
}

export function mergeForm<
  TTarget extends Record<string, any>,
  TSource extends Record<string, any>,
>(args: IMergeFormArgs<TTarget, TSource>) {
  const { target, source, ignore, isEvaluation } = args
  // Reset the status on the target object
  if (!isEvaluation && '__status' in target) {
    ;(target as any).__status = REPEATER_ITEM_STATUS.CREATED
  }
  if ('__evaluatedAt' in target) {
    ;(target as any).__evaluatedAt = isEvaluation
      ? new Date().toISOString()
      : null
  }

  Object.keys(source).reduce((r, k) => {
    // If both values are nodes of kind `OBJECT`,
    // merges their `data` properties
    if (_isKindObject(target[k]) && _isKindObject(source[k])) {
      ;(r as any)[k] = _mergeKindObjectData({
        tValue: target[k],
        sValue: source[k],
        iValue: ignore?.[k],
        isEvaluation,
      })
      return r
    }

    // If both values are objects, merge them
    if (isObject(target[k]) && isObject(source[k])) {
      ;(r as any)[k] = mergeForm({
        target: Object.assign({}, target[k]), // Why do we need to clone the object here?
        source: source[k],
        ignore: ignore?.[k],
        isEvaluation,
      })

      return r
    }

    // Assign the source value to the target, if not ignored
    const shouldIgnore = !!(ignore && k in ignore)
    if (!shouldIgnore) {
      ;(r as any)[k] = parseValue(source[k])
    }

    return r
  }, target)

  return target
}
