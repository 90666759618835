import {
  hasEmptyData,
  hasOwnProperty,
  isDate,
  isEmptyObject,
  isObject,
  isObjectWithEmptyValues,
  makeObjectWithoutPrototype,
  parseValue,
} from './utils'

export function diffManagerForm<L, R>(lhs: L, rhs: R): R
export function diffManagerForm(lhs: unknown, rhs: unknown): unknown {
  // Parse API values
  lhs = parseValue(lhs)
  rhs = parseValue(rhs)

  if (lhs === rhs) return {} // equal return no diff

  // Boolean values doesn't have a default value,
  // so if the value from the API is not defined,
  // we assume that it's `false`.
  if (lhs === null && rhs === false) return {}

  if (!isObject(lhs) || !isObject(rhs)) return rhs // return updated rhs

  if (isDate(lhs) || isDate(rhs)) {
    if (lhs.valueOf() == rhs.valueOf()) return {}
    return rhs
  }

  const lhsObj = lhs as Record<PropertyKey, unknown>
  const rhsObj = rhs as Record<PropertyKey, unknown>

  const deletedValues = Object.keys(lhsObj).reduce<
    Record<PropertyKey, unknown>
  >((acc, key) => {
    if (!hasOwnProperty(rhsObj, key)) {
      acc[key] = undefined
    }

    return acc
  }, makeObjectWithoutPrototype())

  return Object.keys(rhsObj).reduce<Record<PropertyKey, unknown>>(
    (acc: Record<PropertyKey, unknown>, key: PropertyKey) => {
      // As `includeEmptyValues` is set to `false`, the properties that
      // are `null` are returned as `undefined`, so we need to parse them.
      const rhsParsed = parseValue(rhsObj[key])

      if (!hasOwnProperty(lhsObj, key) && rhsParsed !== null) {
        // If the form added empty `{ data: [] }`, return no diff
        if (hasEmptyData(rhsObj[key])) {
          return acc
        }

        // Field Collection is a node that groups multiple fields into an object.
        // If the value from the API is not defined, we assume that it's an
        // object with empty values.
        if (isObject(rhsParsed) && isObjectWithEmptyValues(rhsParsed)) {
          return acc
        }

        acc[key] = rhsObj[key] // return added r key
        return acc
      }

      // `objectReferenceId` should not be compared
      if (key === 'objectReferenceId') {
        return acc
      }

      const difference = diffManagerForm(lhsObj[key], rhsObj[key])

      // If the difference is empty
      if (isEmptyObject(difference) && !isDate(difference)) return acc // return no diff

      acc[key] = difference // return updated key
      return acc // return updated key
    },
    deletedValues,
  )
}
